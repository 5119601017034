import React from "react";
import './BoldLightSentence.css';
import { Link } from 'react-router-dom';
import ActionButton from '../ActionButton/ActionButton.js';


const BoldLightSentence = ( {className, headText, mainText, ShowLink, buttonText, linkTo, targetBlank} ) => {
const target = targetBlank ? '_blank' : '';

  return (
    <div 
      className={`sentence-section ${className}`}>
        <div className='highlight-text'>
            <p className='text-head'>{headText}</p>
            <p className='text-main'>{mainText}</p>
            {ShowLink && (
              <Link to={linkTo} className='link-button' target={target}>
                  <ActionButton buttonText={buttonText} className='actionbutton' />
              </Link>
            )}
        </div>
    </div>
  );
};

export default BoldLightSentence;
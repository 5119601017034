import React from "react";
import './Header.css';
import AnimatedBlob from '../../SmallComponents/AnimatedBlob/AnimatedBlob.js';
import ScrollDownIndicator from '../../SmallComponents/ScrollDownIndicator/ScrollDownIndicator.js';


const Header = () => {
  return (
    <div className='header'>
        <AnimatedBlob />
        <div className='header-text'>
            <div className='title'>
                <div className='name-occupation'> 
                    <h1 className='name'>CRISMARY</h1>
                    <h2 className='subtitle big-screen'>UX Designer</h2>
                </div>
                <h1 className='surname'>GONCALVES</h1>
                <h2 className='subtitle small-screen'>UX Designer</h2>
            </div>
            <ScrollDownIndicator className={'scroll-box'}/>
        </div>
    </div>
  );
};

export default Header;
import React from "react";
import "./BlobImage.css";

const BlobImage = ({image}) => {

  return (
    <div class="blob-image" >
        <img 
          src={image}  
          alt=''  
        />
    </div>
  );
};

export default BlobImage;

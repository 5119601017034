import React from "react";
import "./AnimatedBlob.css";


const AnimatedBlob = () => {


    return (
        <section className="blob-section">
            <div className="blob"></div>
        </section>
    );
};


export default AnimatedBlob;

import React from "react";
import "./ImagesRail.css";

const ImagesRail = ({className, images, label }) => {

  return (
    <div className="images-rail-section">
      {label && <span className="label">{label}</span> }
      <div className={`images-rail ${className}`} >
        {images.map((image, index) => (
          <div className='rail-img-container'>
              <img src={image} alt='' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagesRail;

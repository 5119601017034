import React, { useEffect, useRef } from "react";

import Navbar from '../Components/Navbar/Navbar.js';
import Header from '../Components/Header/Header.js';
import Footer from '../Components/Footer/Footer.js';
import ProjectCards from '../Components/ProjectCards/ProjectCards.js';
import BoldLightSentence from '../SmallComponents/BoldLightSentence/BoldLightSentence.js';
import AboutContent from '../Texts/AboutContent.json';


function Home() {
  const isDynamic=true;
  const itemRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      itemRef.current.forEach((item, index) => {
        const top = item.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.75) {
          item.classList.add("slide-up");
        }
        if(window.scrollY === 0) {
          item.classList.remove("slide-up");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <body>
        <Navbar dynamic={isDynamic} />
        <Header />
        <div ref={(el) => (itemRef.current[0] = el)}>
          <BoldLightSentence headText={AboutContent.homeAbout.headline} mainText={AboutContent.homeAbout.subhead} ShowLink={true} buttonText='Get to know me' linkTo='/about' targetBlank={false}
          className='home-about to-be-scrolled'/>
        </div>
        <ProjectCards />
        <Footer />
    </body>
 );
}

export default Home;


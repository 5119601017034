import React, { useEffect, useRef } from "react";
import './BulletPoints.css';


const BulletPoints = ( {intro, points, className} ) => {
    const itemRef = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
        itemRef.current.forEach((item, index) => {
            const top = item.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (top < windowHeight * 0.75) {
                item.classList.add("slide-up");
            }
            if(window.scrollY === 0) {
                item.classList.remove("slide-up");
            }
        });
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`bullet-points-section ${className}`}>
            <div className='bullet-points'>
                <p ref={(el) => (itemRef.current[0] = el)}  className='text-head'>{intro}</p>
                <ul>
                    {Object.entries(points).map(([key, value], index) => (
                        <li key={key} ref={(el) => (itemRef.current[index+1] = el)}>
                            <p className="point-title">{value.title}</p>
                            <p className="point-text">{value.content}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default BulletPoints;
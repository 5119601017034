import React, { useEffect, useRef } from "react";

import './AboutComponent.css';
import AboutContent from '../../Texts/AboutContent.json';
import personalPhoto from '../../Images/profile-pic.jpg';

import Values from '../../SmallComponents/Values/Values.js';
import Quote from '../../SmallComponents/Quote/Quote.js';
import BlobImage from "../../SmallComponents/BlobImage/BlobImage.js";
import BoldLightSentence from '../../SmallComponents/BoldLightSentence/BoldLightSentence.js';
import BulletPoints from '../../SmallComponents/BulletPoints/BulletPoints.js';


const AboutComponent = () => {
  const itemRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      itemRef.current.forEach((item, index) => {
        const top = item.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.75) {
          item.classList.add("slide-up");
        }
        if(window.scrollY === 0) {
          item.classList.remove("slide-up");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div className='about'>

        <div className='about-section head'>
          <BoldLightSentence 
            headText={AboutContent.aboutMe.headline} 
            mainText={AboutContent.aboutMe.subhead} 
            ShowLink={false} 
            buttonText=''
            linkTo=''
            targetBlank={false}
            className='about-head slide-up-on-load'/>
          <div className='blob-img-section slide-up-on-load'>
            <BlobImage  image={personalPhoto}/>
          </div>
        </div>
        <div className='values-section' ref={(el) => (itemRef.current[0] = el)}>
          <BoldLightSentence 
            headText={AboutContent.values.head} 
            mainText={AboutContent.values.subhead} 
            ShowLink={false}
            className='experience-head values-text to-be-scrolled'/>
          <Values />
        </div>
        <div className='about-section about-content' ref={(el) => (itemRef.current[1] = el)}>
          <BoldLightSentence 
            headText={AboutContent.aboutMe.experienceheading}  
            ShowLink={false}
            className='experience-head to-be-scrolled'
          />
          <BulletPoints
            intro={AboutContent.aboutMe.experiencemain.intro} 
            points={AboutContent.aboutMe.experiencemain.points}
          />
      </div>

      <div ref={(el) => (itemRef.current[2] = el)}>
        <Quote quote={AboutContent.quote.quote} author={AboutContent.quote.author}/>
      </div>

    </div>
  );
};

export default AboutComponent;


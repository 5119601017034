import Navbar from '../Components/Navbar/Navbar.js';
import Footer from '../Components/Footer/Footer.js';
import HarleyHospitalComponent from '../Components/Portfolio/HarleyHospitalComponent/HarleyHospitalComponent.js';



function HarleyHospital() {
  const isDynamic = true;

  return (
    <body>
        <Navbar dynamic = {isDynamic} />
        <HarleyHospitalComponent />
        <Footer />
    </body>
 );
}

export default HarleyHospital;


import React, { useEffect, useRef } from "react";
import './ProjectCards.css';
import { Link } from 'react-router-dom';

import HHdashboardPhone from '../../Images/HabitHero/dashboardPhone.png';
import HarleydashboardPhone from '../../Images/HarleyHospital/dashboardPhone.png';
import GRmybooksPhone from '../../Images/Goodreads/myBooksPhone.png';
import HabitHeroText from '../../Texts/HabitHero.json';
import HarleyHospitalText from '../../Texts/HarleyHospital.json';
import GoodreadsText from '../../Texts/Goodreads.json';

import ActionButton from '../../SmallComponents/ActionButton/ActionButton.js';

const ProjectCards = () => {
    const itemRef = useRef([]);

    useEffect(() => {
      const handleScroll = () => {
        itemRef.current.forEach((item, index) => {
          const top = item.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
          if (top < windowHeight * 0.85) {
            item.classList.add("slide-up");
          }
          if(window.scrollY === 0) {
            item.classList.remove("slide-up");
          }
        });
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    
    return (
        <div className='work'>
            <div className="work-title">
                <h3>Projects</h3>
            </div>

            <div className='work-section' ref={(el) => (itemRef.current[0] = el)}>
                <div className="img-section">
                    <img src={HHdashboardPhone} alt='' />
                </div>
                <div className='work-text'>
                    <h4>{HabitHeroText.title}</h4>
                    <p>{HabitHeroText.subtitle}</p>
                    <Link to='/portfolio/habithero' className='link-button'>
                        <ActionButton buttonText="View case study" />
                    </Link> 
                </div>
            </div>

            <div className='work-section' ref={(el) => (itemRef.current[2] = el)}>
                <div className="img-section">
                    <img src={HarleydashboardPhone} alt='' />
                </div>
                <div className='work-text'>
                    <h4>{HarleyHospitalText.title}</h4>
                    <p>{HarleyHospitalText.subtitle}</p>
                    <Link to='/portfolio/harleyhospital' className='link-button'>
                        <ActionButton buttonText="View case study" />
                    </Link> 
                </div>
            </div>

            <div className='work-section' ref={(el) => (itemRef.current[1] = el)}>
                <div className="img-section">
                    <img src={GRmybooksPhone} alt='' />
                </div>
                <div className='work-text'>
                    <h4>{GoodreadsText.title}</h4>
                    <p>{GoodreadsText.subtitle}</p>
                    <Link to='/portfolio/goodreads' className='link-button'>
                        <ActionButton buttonText="View case study" />
                    </Link> 
                </div>
            </div>

            

        </div>
    );
};

export default ProjectCards;

import React, { useEffect, useRef } from "react";
import "./SmallBigImg.css";

const SmallBigImg = ({className, smallImg, smallImglabel, bigImg, bigImgLabel, slideUp}) => {
    const imagesRef = useRef([]);

    useEffect(() => {
      const handleScroll = () => {
        imagesRef.current.forEach((img, index) => {
          const top = img.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
          if (top < windowHeight * 0.75) {
            img.classList.add("slide-up");
          }
          if(window.scrollY === 0) {
            img.classList.remove("slide-up");
          }
        });
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);


  return (
    <div className={`small-big-img-section ${className}`}>
        
        {/* <div className="img-container small-img-container to-be-scrolled" ref={(el) => (imagesRef.current[0] = el)}> */}
        <div className={`img-container small-img-container ${slideUp ? 'to-be-scrolled' : 'slide-up'} `} ref={(el) => (imagesRef.current[0] = el)}>

            {smallImglabel && <span className="label">{smallImglabel}</span> }
            <div className='small-img' >
                <img src={smallImg} alt='' />
            </div>
        </div>

        <div className={`img-container big-img-container ${slideUp ? 'to-be-scrolled' : 'slide-up'} `} ref={(el) => (imagesRef.current[1] = el)}>
            {bigImgLabel && <span className="label">{bigImgLabel}</span> }
            <div className='big-img' >
                <img src={bigImg} alt='' />
            </div>
        </div>

    </div>
  );
};

export default SmallBigImg;

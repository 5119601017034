import React from "react";

import '../GeneralProjects.css';

import ProjectHeader from '../ProjectHeader/ProjectHeader.js';
import Quote from '../../../SmallComponents/Quote/Quote.js';
import SingleImg from '../../../SmallComponents/SingleImg/SingleImg.js';
import ImagesRail from "../../../SmallComponents/ImagesRail/ImagesRail.js";
import SmallBigImg from "../../../SmallComponents/SmallBigImg/SmallBigImg.js";
import MoreProjects from "../../MoreProjects/MoreProjects.js";

import HabitHeroText from '../../../Texts/HabitHero.json';

import dashboardPhone from '../../../Images/HabitHero/dashboardPhone.png';
import MVPHome from '../../../Images/HabitHero/MVP/Home.png';
import MVPActivities from '../../../Images/HabitHero/MVP/ActivityInsight.png';
import MVPSteps from '../../../Images/HabitHero/MVP/ActivitySteps.png';
import MVPGoals from '../../../Images/HabitHero/MVP/GoalHighlights.png';
import UserPersona from '../../../Images/HabitHero/Other/userPersona.png';
import MindMap from '../../../Images/HabitHero/Other/MindMap.png';

import SleepDetails from '../../../Images/HabitHero/HighFi/SleepDetails.png';
import SleepYear from '../../../Images/HabitHero/HighFi/SleepYear.png';
import HeartRateWeek from '../../../Images/HabitHero/HighFi/HeartRateWeek.png';
import HeartRateToday from '../../../Images/HabitHero/HighFi/HeartRateToday.png';
import AdviceDetails from '../../../Images/HabitHero/HighFi/AdviceDetails.png';
import AdvicesScreen from '../../../Images/HabitHero/HighFi/AdvicesScreen.png';
import AdvicesUpdates from '../../../Images/HabitHero/HighFi/AdvicesUpdates.gif';
import TrackActivity from '../../../Images/HabitHero/HighFi/TrackActivity.gif';
import MainFlow from '../../../Images/HabitHero/HighFi/MainFlow.gif';

import MoodBoard from '../../../Images/HabitHero/Other/Moodboard.png';
import StyleGuide from '../../../Images/HabitHero/Other/StyleGuide.png';


const HabitHeroComponent = () => {

    const MVPImages = [MVPHome, MVPActivities, MVPSteps, MVPGoals];
    const ProgressReportImages = [HeartRateToday, HeartRateWeek, SleepYear, SleepDetails];
    const AdvicesImages = [AdvicesScreen, AdviceDetails];
    const TrackActivityUpdates = [TrackActivity];
    const AdvicesUpdatesGifs = [AdvicesUpdates];
    const prototypeLink = 'https://www.figma.com/proto/wN3ldZfphTnBLKsg1bJxku/HH-wireframes-and-prototypes?page-id=271%3A309&node-id=271-2296&viewport=0%2C345%2C0.26&t=xSf6pvx7bT1Bh0Eb-1&scaling=scale-down&starting-point-node-id=271%3A2296&show-proto-sidebar=1';
  
    return (
    <div className='project-page'>
        <ProjectHeader title={HabitHeroText.title} overview={HabitHeroText.overview} image={dashboardPhone} protypeLink={prototypeLink}/>
        
        <div className="project-content">
            <div className='project-section'>
                <div className='project-section-text'>
                    <h2 className='text-head'>{HabitHeroText.background.title}</h2>
                    <p className='text-main'>{HabitHeroText.background.paragraphs.one}</p>
                    <p className='text-main subsequent-paragraph'>{HabitHeroText.background.paragraphs.two}</p>
                </div>
            </div>
            <div className='project-section'>
                <div className='project-section-text'>
                    <h2 className='text-head'>{HabitHeroText.mvpReview.title}</h2>
                    <p className='text-main'>{HabitHeroText.mvpReview.paragraphs.one}</p>
                    <p className='text-main subsequent-paragraph'>{HabitHeroText.mvpReview.paragraphs.two}</p> 

                    <ul className="subsequent-paragraph normal-ul text-main">
                        <li><span className='bold-text'>{HabitHeroText.mvpReview.points.one.title}</span> {HabitHeroText.mvpReview.points.one.paragraph}</li>
                        <li><span className='bold-text'>{HabitHeroText.mvpReview.points.two.title}</span> {HabitHeroText.mvpReview.points.two.paragraph}</li>
                    </ul>

                    <p className='subsequent-paragraph text-main'>{HabitHeroText.mvpReview.paragraphs.three}</p>
                </div>
                <ImagesRail images={MVPImages} label='Previous Design'/>
            </div>
            
            <div className='project-section'>
                <div className='project-section-text'>
                    <h2 className='text-head'>{HabitHeroText.research.title}</h2>
                    <p className='text-main'>{HabitHeroText.research.paragraph}</p>

                    <ul className="subsequent-paragraph normal-ul text-main">
                        <li><span className='bold-text'>{HabitHeroText.research.points.one.title}</span> {HabitHeroText.research.points.one.paragraph}</li>
                        <li><span className='bold-text'>{HabitHeroText.research.points.two.title}</span> {HabitHeroText.research.points.two.paragraph}</li>
                        <li><span className='bold-text'>{HabitHeroText.research.points.three.title}</span> {HabitHeroText.research.points.three.paragraph}</li>
                    </ul>

                    <div className="sub-area">
                        <h3 className='text-subhead'>{HabitHeroText.userAnalysis.title}</h3>
                        <p className='text-main'>{HabitHeroText.userAnalysis.paragraph}</p>
                        
                        <ul className="subsequent-paragraph normal-ul text-main">
                            <li><span className='bold-text'>{HabitHeroText.userAnalysis.points.one.title}</span> {HabitHeroText.userAnalysis.points.one.paragraph}</li>
                            <li><span className='bold-text'>{HabitHeroText.userAnalysis.points.two.title}</span> {HabitHeroText.userAnalysis.points.two.paragraph}</li>
                            <li><span className='bold-text'>{HabitHeroText.userAnalysis.points.three.title}</span> {HabitHeroText.userAnalysis.points.three.paragraph}</li>
                            <li><span className='bold-text'>{HabitHeroText.userAnalysis.points.four.title}</span> {HabitHeroText.userAnalysis.points.four.paragraph}</li>
                        </ul>
                    </div>
                </div>
                <SingleImg image={UserPersona} label='User Persona'/>
            </div>
            
            <div className='project-section' >
                <div className='project-section-text'>
                    <h2 className='text-head'>{HabitHeroText.ideation.title}</h2>
                    <p className='text-main'>{HabitHeroText.ideation.paragraphs.one}</p>
                </div>
                <div className="slide-up">
                    <Quote quote={HabitHeroText.hmw.points.one.title} className='project-quote'/>
                </div>
                <div className="slide-up">
                    <Quote quote={HabitHeroText.hmw.points.two.title} className='project-quote'/>
                </div>
                <div className="">
                    <SingleImg image={MindMap} label='Mind Map'/>
                </div>
            </div>
    
            <div className='project-section'>
                <div className='project-section-text'>
                    <h2 className='text-head'>{HabitHeroText.newConcept.title}</h2>
                    <p className='text-main'>{HabitHeroText.newConcept.paragraphs.one}</p>
                    <p className='text-main subsequent-paragraph'>{HabitHeroText.newConcept.paragraphs.two}</p>
                </div>
                <div className='project-section-text'>
                    <div className="sub-area">
                        <h3 className='text-subhead'>{HabitHeroText.newConcept.subAreas.area1.title}</h3>
                        <p className='text-main'>{HabitHeroText.newConcept.subAreas.area1.paragraphs.one}</p>
                        <p className='text-main subsequent-paragraph'>{HabitHeroText.newConcept.subAreas.area1.paragraphs.two}</p>
                    </div>
                </div>
                <ImagesRail images={ProgressReportImages} label='Progress Tracker Screens'/>
                <div className='project-section-text'>
                    <div className="sub-area">
                        <h3 className='text-subhead'>{HabitHeroText.newConcept.subAreas.area2.title}</h3>
                        <p className='text-main'>{HabitHeroText.newConcept.subAreas.area2.paragraphs.one}</p>
                    </div>
                </div>
                <ImagesRail images={AdvicesImages} label='Wellness Advice Screens'/>
            </div>

            <div className='project-section'>
                <div className='project-section-text'>
                    <h2 className='text-head'>{HabitHeroText.userTesting.title}</h2>
                    <p className='text-main'>{HabitHeroText.userTesting.paragraph}</p>
                    <ul className="subsequent-paragraph normal-ul text-main">
                        <li><span className='bold-text'>{HabitHeroText.userTesting.points.one.title}</span> {HabitHeroText.userTesting.points.one.paragraph}</li>
                        <li><span className='bold-text'>{HabitHeroText.userTesting.points.two.title}</span> {HabitHeroText.userTesting.points.two.paragraph}</li>
                    </ul>
                </div>

                <div className="sub-area">
                    <div className='project-section-text'>
                        <h3 className='text-subhead'>{HabitHeroText.userTesting.subAreas.area1.title}</h3>
                        <p className='text-main'>{HabitHeroText.userTesting.subAreas.area1.paragraph}</p>
                    </div>
                    <div className="text-and-img">
                        <div className='project-section-text'>
                            <ul className="subsequent-paragraph normal-ul text-main">
                                <li><span className='bold-text'>{HabitHeroText.userTesting.subAreas.area1.points.one.title}</span> {HabitHeroText.userTesting.subAreas.area1.points.one.paragraph}</li>
                            </ul>
                        </div>
                        <SingleImg image={TrackActivityUpdates} className='prototype-img' />
                    </div> 
                    <div className="text-and-img text-and-img-top-margin">
                        <div className='project-section-text'>
                            <ul className="subsequent-paragraph normal-ul text-main">
                                <li><span className='bold-text'>{HabitHeroText.userTesting.subAreas.area1.points.two.title}</span> {HabitHeroText.userTesting.subAreas.area1.points.two.paragraph}</li>
                            </ul>
                        </div>
                        <SingleImg image={AdvicesUpdatesGifs} className='prototype-img' />
                    </div>
                </div>
            </div>

            
            <div className='project-section'>
                <div className='project-section-text'>
                    <h2 className='text-head'>{HabitHeroText.ui.title}</h2>
                    <p className='text-main'>{HabitHeroText.ui.paragraphs.one}</p>

                    <ul className="subsequent-paragraph normal-ul text-main">
                        <li><span className='bold-text'>{HabitHeroText.ui.points.one.title}</span> {HabitHeroText.ui.points.one.paragraph}</li>
                        <li><span className='bold-text'>{HabitHeroText.ui.points.two.title}</span> {HabitHeroText.ui.points.two.paragraph}</li>
                        <li><span className='bold-text'>{HabitHeroText.ui.points.three.title}</span> {HabitHeroText.ui.points.three.paragraph}</li>
                    </ul>
                </div>
                <SmallBigImg smallImg={MoodBoard} smallImglabel='Moodboard' bigImg={StyleGuide} bigImgLabel='Style Guide' slideUp={false}/>
                
                <div className="text-and-img text-and-img-top-margin">
                    <div className='project-section-text'>
                        <p className='text-main'>{HabitHeroText.ui.paragraphs.two}</p>
                    </div>
                <SingleImg image={MainFlow} className='prototype-img' />
                </div>
                
            </div>
        

            <div className='project-section'>
                <div className='project-section-text '>
                    <div>
                        <h2 className='text-head'>{HabitHeroText.conclusion.title}</h2>
                        <p className='text-main'>{HabitHeroText.conclusion.paragraphs.one}</p>
                        <p className='text-main subsequent-paragraph'>{HabitHeroText.conclusion.paragraphs.two}</p>
                    </div>
                    
                </div>
            </div>

            <MoreProjects project1='harleyhospital' project2='goodreads'/>
        </div>
    </div>
  );
};

export default HabitHeroComponent;


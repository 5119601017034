import React, { useEffect, useRef } from "react";

import './ProjectHeader.css';
import BoldLightSentence from '../../../SmallComponents/BoldLightSentence/BoldLightSentence.js';



const ProjectHeader = ( {title, overview, image, protypeLink} ) => {
  const itemRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      itemRef.current.forEach((item, index) => {
        const top = item.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.75) {
          item.classList.add("slide-up");
        }
        if(window.scrollY === 0) {
          item.classList.remove("slide-up");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  var showLink = false;
  var protoLink = '';
  if (protypeLink && protypeLink !== '') {
    showLink = true;
    protoLink = protypeLink;
  }


  return (
    <div className='project-head'>

        <div className='project-head-section'>
          <BoldLightSentence 
            headText={title} 
            mainText={overview} 
            ShowLink={showLink} 
            buttonText='See prototype'
            linkTo={protoLink}
            targetBlank={true}
            className='project-head '/>
          <div className='project-cover-img '>
            <img 
              src={image}  
              alt=''  
            />
          </div>
        </div>
 
        </div>

  );
};

export default ProjectHeader;


import Navbar from '../Components/Navbar/Navbar.js';
import Footer from '../Components/Footer/Footer.js';
import AboutComponent from '../Components/About/AboutComponent.js';

function About() {
  const isDynamic = true;

  return (
    <body>
        <Navbar dynamic = {isDynamic} />

       <AboutComponent /> 

        <Footer />
    </body>
 );
}

export default About;


import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home  from './Pages/Home.js'
import About from './Pages/About.js'
import HabitHero  from './Pages/HabitHero.js'
import Goodreads  from './Pages/Goodreads.js'
import HarleyHospital  from './Pages/HarleyHospital.js'






function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio/habithero" element={<HabitHero />} />
        <Route path="/portfolio/goodreads" element={<Goodreads />} />
        <Route path="/portfolio/harleyhospital" element={<HarleyHospital />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default App;

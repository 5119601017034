import React from "react";
import './MoreProjects.css';
import { Link } from 'react-router-dom';
import HHdashboardPhone from '../../Images/HabitHero/dashboardPhone.png';
import GRmybooksPhone from '../../Images/Goodreads/myBooksPhone.png';
import HarleydashboardPhone from '../../Images/HarleyHospital/dashboardPhone.png';

import HarleyHospitalText from '../../Texts/HarleyHospital.json';
import GoodreadsText from '../../Texts/Goodreads.json';
import HabitHeroText from '../../Texts/HabitHero.json';


const MoreProjects = ({project1, project2}) => {
    
    const projectsToShow = [project1, project2]; 

    return (
        <div className='more-projects-section'>

            <div className="more-project-title">
                <h3>More Projects</h3>
            </div>

            <div className="next-projects-section">
              
              {projectsToShow.includes('habithero') && ( 
                  <Link to='/portfolio/habithero' className='link-button'>
                    <div className='next-project'>
                      <div className="next-img-section">
                        <img src={HHdashboardPhone} alt='' />
                      </div>
                        <div className='project-title'>
                            <h4>{HabitHeroText.title}</h4>
                        </div>
                    </div>
                </Link> 
              )}

              {projectsToShow.includes('harleyhospital') && ( 
                <Link to='/portfolio/harleyhospital' className='link-button'>
                    <div className='next-project'>
                        <div className="next-img-section">
                          <img src={HarleydashboardPhone} alt='' />
                        </div>
                        <div className='project-title'>
                            <h4>{HarleyHospitalText.title}</h4>
                        </div>
                    </div>
                </Link>
              )}

              {projectsToShow.includes('goodreads') && ( 
                <Link to='/portfolio/goodreads' className='link-button'>
                    <div className='next-project'>
                        <div className="next-img-section">
                          <img src={GRmybooksPhone} alt='' />
                        </div>
                        <div className='project-title'>
                            <h4>{GoodreadsText.title}</h4>
                        </div>
                    </div>
                </Link>
              )}

            </div> 

        </div>
    );
};

export default MoreProjects;


import Navbar from '../Components/Navbar/Navbar.js';
import Footer from '../Components/Footer/Footer.js';
import GoodreadsComponent from '../Components/Portfolio/GoodreadsComponent/GoodreadsComponent.js';



function Goodreads() {
  const isDynamic = true;

  return (
    <body>
        <Navbar dynamic = {isDynamic} />
        <GoodreadsComponent />
        <Footer />
    </body>
 );
}

export default Goodreads;


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter } from "react-router-dom"
import ScrollToTop from './Utils/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter basename='/'>
    <React.StrictMode>
    <ScrollToTop />

      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" integrity="sha256-2TnSHycBDAm2wpZmgdi0z81kykGPJAkiUY+Wf97RbvY=" crossorigin="anonymous"/>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700;800&family=Open+Sans:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&family=Oswald:wght@200..700&display=swap" rel="stylesheet"/>
      <App />

      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.min.js" integrity="sha256-gOQJIa9+K/XdfAuBkg2ONAdw5EnQbokw/s2b8BqsRFg=" crossorigin="anonymous"></script>

    </React.StrictMode>
  </BrowserRouter>
);


import React from "react";
import '../GeneralProjects.css';

import ProjectHeader from '../ProjectHeader/ProjectHeader.js';
import Quote from '../../../SmallComponents/Quote/Quote.js';
import SingleImg from '../../../SmallComponents/SingleImg/SingleImg.js';
import MoreProjects from "../../MoreProjects/MoreProjects.js";

import HarleyHospitalText from '../../../Texts/HarleyHospital.json';

import EmpathyMap from '../../../Images/HarleyHospital/EmpathyMap.png';
import IEmatrix from '../../../Images/HarleyHospital/IEmatrix.png';
import dashboardPhone from '../../../Images/HarleyHospital/dashboardPhone.png';
import BookingFlow from '../../../Images/HarleyHospital/BookingFlow.gif';
import ConsultationFlow from '../../../Images/HarleyHospital/OnlineConsultationFlow.gif';
import RescheduleFlow from '../../../Images/HarleyHospital/RescheduleFlow.gif';
import CancelFlow from '../../../Images/HarleyHospital/CancelFlow.gif';
import RebookFlow from '../../../Images/HarleyHospital/RebookFlow.gif';


const HarleyHospitalComponent = () => {
  const prototypeLink = 'https://www.figma.com/proto/CX6qhjCZjjXy0ps89xR0bi/Presenting_Design_Solution_-_Team_4?page-id=12%3A9054&node-id=12-12634&viewport=-3370%2C-957%2C0.53&t=TxpP6OT1Hm4BexQ9-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=12%3A12575&show-proto-sidebar=1';

  return (
    <div className='project-page'>
      <ProjectHeader title={HarleyHospitalText.title} overview={HarleyHospitalText.overview} image={dashboardPhone} protypeLink={prototypeLink}/>

      <div className="project-content">

        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{HarleyHospitalText.background.title}</h2>
            <p className='text-main'>{HarleyHospitalText.background.paragraphs.one}</p>
            <p className='text-main subsequent-paragraph'>{HarleyHospitalText.background.paragraphs.two}</p>
            <p className='text-main subsequent-paragraph'>{HarleyHospitalText.background.paragraphs.three}</p>
            
            <ul className="subsequent-paragraph normal-ul text-main">
              <li><span className='bold-text'>{HarleyHospitalText.background.points1.one.title}</span> {HarleyHospitalText.background.points1.one.paragraph}</li>
              <li><span className='bold-text'>{HarleyHospitalText.background.points1.two.title}</span> {HarleyHospitalText.background.points1.two.paragraph}</li>
              <li><span className='bold-text'>{HarleyHospitalText.background.points1.three.title}</span> {HarleyHospitalText.background.points1.three.paragraph}</li>
            </ul>

            <p className='text-main subsequent-paragraph'>{HarleyHospitalText.background.paragraphs.four}</p>

            <ul className="subsequent-paragraph normal-ul text-main">
              <li><span className='bold-text'>{HarleyHospitalText.background.points2.one.title}</span> {HarleyHospitalText.background.points2.one.paragraph}</li>
              <li><span className='bold-text'>{HarleyHospitalText.background.points2.two.title}</span> {HarleyHospitalText.background.points2.two.paragraph}</li>
            </ul>

            <p className='text-main subsequent-paragraph'>{HarleyHospitalText.background.paragraphs.five}</p>

          </div>
        </div>

        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{HarleyHospitalText.userproblem.title}</h2>
            <p className='text-main'>{HarleyHospitalText.userproblem.paragraphs.one}</p>

            <ul className="subsequent-paragraph normal-ul text-main">
              <li><span className='bold-text'>{HarleyHospitalText.userproblem.points.one.title}</span> {HarleyHospitalText.userproblem.points.one.paragraph}</li>
              <li><span className='bold-text'>{HarleyHospitalText.userproblem.points.two.title}</span> {HarleyHospitalText.userproblem.points.two.paragraph}</li>
              <li><span className='bold-text'>{HarleyHospitalText.userproblem.points.three.title}</span> {HarleyHospitalText.userproblem.points.three.paragraph}</li>
              <li><span className='bold-text'>{HarleyHospitalText.userproblem.points.four.title}</span> {HarleyHospitalText.userproblem.points.four.paragraph}</li>
              <li><span className='bold-text'>{HarleyHospitalText.userproblem.points.five.title}</span> {HarleyHospitalText.userproblem.points.five.paragraph}</li>
            </ul>

            <p className='text-main subsequent-paragraph'>{HarleyHospitalText.userproblem.paragraphs.two}</p>
            <p className='text-main subsequent-paragraph'><span className='bold-text'>{HarleyHospitalText.userproblem.paragraphs.three}</span></p>

          </div>
          <div className="slide-up">
            <Quote quote={HarleyHospitalText.userproblem.problemStatement} className='project-quote'/>
          </div>

          <div className='project-section-text'>
            <div className="sub-area">
              <h3 className='text-subhead'>{HarleyHospitalText.userproblem.subAreas.area1.title}</h3>
              <p className='text-main'>{HarleyHospitalText.userproblem.subAreas.area1.paragraphs.one}</p>

              <ul className="subsequent-paragraph normal-ul text-main">
                <li><span className='bold-text'>{HarleyHospitalText.userproblem.subAreas.area1.points.one.title}</span> {HarleyHospitalText.userproblem.subAreas.area1.points.one.paragraph}</li>
                <li><span className='bold-text'>{HarleyHospitalText.userproblem.subAreas.area1.points.two.title}</span> {HarleyHospitalText.userproblem.subAreas.area1.points.two.paragraph}</li>
                <li><span className='bold-text'>{HarleyHospitalText.userproblem.subAreas.area1.points.three.title}</span> {HarleyHospitalText.userproblem.subAreas.area1.points.three.paragraph}</li>
              </ul>

              <p className='text-main subsequent-paragraph'>{HarleyHospitalText.userproblem.subAreas.area1.paragraphs.two}</p>
            </div>
          </div>
          <SingleImg image={EmpathyMap} label='Empathy Map' className='maxWidth60' />
        </div>
     
        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{HarleyHospitalText.design.title}</h2>
            <p className='text-main'>{HarleyHospitalText.design.paragraph}</p>
          </div>
          <SingleImg image={IEmatrix} label='Impact-effort Matrix' className='maxWidth60' />
        
          <div className="sub-area">
            <div className='project-section-text'>
              <h3 className='text-subhead'>{HarleyHospitalText.design.subAreas.area1.title}</h3>
            </div>
            <div className="text-and-img">
              <div className='project-section-text'>
                <p className='text-main'>{HarleyHospitalText.design.subAreas.area1.paragraphs.one}</p>
              </div>
              <SingleImg image={BookingFlow}  className='prototype-img' />
            </div>  

            <div className="text-and-img text-and-img-top-margin">
              <div className='project-section-text'>
                <p className='text-main'>{HarleyHospitalText.design.subAreas.area1.paragraphs.two}</p>
              </div>
              <SingleImg image={ConsultationFlow}  className='prototype-img' />
            </div>  
          </div>

          <div className="sub-area">
            <div className='project-section-text'>
              <h3 className='text-subhead'>{HarleyHospitalText.design.subAreas.area2.title}</h3>
            </div>
            <div className="text-and-img">
              <div className='project-section-text'>
                <p className='text-main'>{HarleyHospitalText.design.subAreas.area2.paragraphs.one}</p>
              </div>
              <SingleImg image={RescheduleFlow}  className='prototype-img' />
            </div>  

            <div className="text-and-img text-and-img-top-margin">
              <div className='project-section-text'>
                <p className='text-main'>{HarleyHospitalText.design.subAreas.area2.paragraphs.two}</p>
              </div>
              <SingleImg image={CancelFlow}  className='prototype-img' />
            </div>  
          </div>
          
          <div className="sub-area">
            <div className='project-section-text'>
              <h3 className='text-subhead'>{HarleyHospitalText.design.subAreas.area3.title}</h3>
            </div>
            <div className="text-and-img">
              <div className='project-section-text'>
                <p className='text-main'>{HarleyHospitalText.design.subAreas.area3.paragraphs.one}</p>
              </div>
              <SingleImg image={RebookFlow}  className='prototype-img' />
            </div>  
          </div>
        
        </div>
          
 
        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{HarleyHospitalText.userTesting.title}</h2>
            <p className='text-main'>{HarleyHospitalText.userTesting.paragraphs.one} {HarleyHospitalText.userTesting.paragraphs.two}</p>
            
            <div className="sub-area">
              <h3 className='text-subhead'>{HarleyHospitalText.userTesting.points.one.title}</h3>
              <ul className="single-point-ul normal-ul  text-main">
                <li>
                  <p>{HarleyHospitalText.userTesting.points.one.paragraph}</p>
                  <ul className="normal-ul sub-ul">
                    <li>
                      <span className='bold-text'>{HarleyHospitalText.userTesting.points.one.subPoint.title}</span> {HarleyHospitalText.userTesting.points.one.subPoint.paragraph}</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="sub-area">
              <h3 className='text-subhead'>{HarleyHospitalText.userTesting.points.two.title}</h3>
              <ul className="single-point-ul normal-ul  text-main">
                <li>
                  <p>{HarleyHospitalText.userTesting.points.two.paragraph}</p>
                  <ul className="normal-ul sub-ul">
                    <li>
                      <span className='bold-text'>{HarleyHospitalText.userTesting.points.two.subPoint.title}</span> {HarleyHospitalText.userTesting.points.two.subPoint.paragraph}</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="sub-area">
              <h3 className='text-subhead'>{HarleyHospitalText.userTesting.points.three.title}</h3>
              <ul className="single-point-ul normal-ul  text-main">
                <li>
                  <p>{HarleyHospitalText.userTesting.points.three.paragraph}</p>
                  <ul className="normal-ul sub-ul">
                    <li>
                      <span className='bold-text'>{HarleyHospitalText.userTesting.points.three.subPoint.title}</span> {HarleyHospitalText.userTesting.points.three.subPoint.paragraph}</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="sub-area">
              <h3 className='text-subhead'>{HarleyHospitalText.userTesting.points.four.title}</h3>
              <ul className="single-point-ul normal-ul  text-main">
                <li>
                  <p>{HarleyHospitalText.userTesting.points.four.paragraph}</p>
                  <ul className="normal-ul sub-ul">
                    <li>
                      <span className='bold-text'>{HarleyHospitalText.userTesting.points.four.subPoint.title}</span> {HarleyHospitalText.userTesting.points.four.subPoint.paragraph}</li>
                  </ul>
                </li>
              </ul>
            </div>

          </div>
        </div>


        <div className='project-section'>
          <div className='project-section-text '>
            <h2 className='text-head'>{HarleyHospitalText.conclusion.title}</h2>
            <p className='text-main'>{HarleyHospitalText.conclusion.paragraphs.one} {HarleyHospitalText.conclusion.paragraphs.two}</p>
            {/* <p className='text-main subsequent-paragraph'>{HarleyHospitalText.conclusion.paragraphs.two}</p> */}
            <div className="sub-area">
              <h3 className='text-subhead'>{HarleyHospitalText.conclusion.subAreas.area1.title}</h3>
              <ul className="normal-ul text-main">
                <li>{HarleyHospitalText.conclusion.subAreas.area1.points.one}</li>
                <li>{HarleyHospitalText.conclusion.subAreas.area1.points.two}</li>
                <li>{HarleyHospitalText.conclusion.subAreas.area1.points.three}</li>
                <li>{HarleyHospitalText.conclusion.subAreas.area1.points.four}</li>
              </ul>
            </div>

            <div className="sub-area">
              <h3 className='text-subhead'>{HarleyHospitalText.conclusion.subAreas.area2.title}</h3>
              <ul className="normal-ul text-main">
                <li>{HarleyHospitalText.conclusion.subAreas.area2.points.one}</li>
                <li>{HarleyHospitalText.conclusion.subAreas.area2.points.two}</li>
                <li>{HarleyHospitalText.conclusion.subAreas.area2.points.three}</li>
              </ul>
            </div>

            
              
          </div>
        </div>

            

        
         
        <MoreProjects project1='habithero' project2='goodreads'/>
      </div>
    </div>
  );
};

export default HarleyHospitalComponent;


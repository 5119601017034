import React, { useEffect, useRef } from "react";
import './Values.css';
import AboutContent from '../../Texts/AboutContent.json';

const Values = () => {
  const boxesRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      boxesRef.current.forEach((box, index) => {
        const top = box.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (top < windowHeight * 0.75) {
          box.classList.add("slide-up");
        }
        if(window.scrollY === 0) {
          box.classList.remove("slide-up");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (

        <div className="boxes-section row">
            <div 
              ref={(el) => (boxesRef.current[0] = el)}
              className="single-box col-12 col-md-6 ">
              <h5>{AboutContent.values.value1.title}</h5>
              <p className="value-key-phrase">{AboutContent.values.value1.keyphrase}</p>
              <p>{AboutContent.values.value1.description}</p>
            </div>
            <div 
              ref={(el) => (boxesRef.current[1] = el)}
              className="single-box col-12 col-md-6 ">
              <h5>{AboutContent.values.value2.title}</h5>
              <p className="value-key-phrase">{AboutContent.values.value2.keyphrase}</p> 
              <p>{AboutContent.values.value2.description}</p>
            </div>
            <div 
              ref={(el) => (boxesRef.current[2] = el)}
              className="single-box col-12 col-md-6 ">
              <h5>{AboutContent.values.value3.title}</h5>
              <p className="value-key-phrase">{AboutContent.values.value3.keyphrase}</p>
              <p>{AboutContent.values.value3.description}</p>
            </div>
            <div 
              ref={(el) => (boxesRef.current[3] = el)}
              className="single-box col-12 col-md-6 ">
              <h5>{AboutContent.values.value4.title}</h5>
              <p className="value-key-phrase">{AboutContent.values.value4.keyphrase}</p>               
              <p>{AboutContent.values.value4.description}</p>
            </div>
        </div>

  );
};

export default Values;


import React from "react";
import './Quote.css';


const Quote = ({quote, author, className} ) => {
  return (
    
    <div className={`quote-section ${className}`}>
        <p className="quote">{quote}</p>
        {author != null &&
          <p className="author">{author}</p>
        } 
    </div>

  );
};

export default Quote;


import React from "react";
import "./SingleImg.css";

const SingleImg = ({className, image, label }) => {

  return (
    <div className="image-section">
      {label && <span className="label">{label}</span> }
      <div className={`single-img ${className}`} >
            <img src={image} alt='' />
      </div>
    </div>
  );
};

export default SingleImg;

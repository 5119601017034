import Navbar from '../Components/Navbar/Navbar.js';
import Footer from '../Components/Footer/Footer.js';
import HabitHeroComponent from '../Components/Portfolio/HabitHeroComponent/HabitHeroComponent.js';



function HabitHero() {
  const isDynamic = true;

  return (
    <body>
        <Navbar dynamic = {isDynamic} />
        <HabitHeroComponent />
        <Footer />
    </body>
 );
}

export default HabitHero;


import React from "react";
import emailDark from '../../Images/Social/email-dark.png';
import linkedinDark from '../../Images/Social/linkedin-dark.png';

import './Footer.css';

const Footer = () => {
  return (
    <footer >
      <div className="footer-content">
        <h4>Let's Connect!</h4>
        <div className='social-media'>
          <div class="square email">
            <a href="mailto:crismary.design@gmail.com">
              <span></span>
              <div class="content">
                <img src={emailDark} alt='Email logo'/>
              </div>
              </a>
          </div>
          <div class="square linkedin">
            <a href='https://www.linkedin.com/in/crismary/' target='_blank' rel='noopener noreferrer'>
              <span></span>
              <div class="content">
                <img src={linkedinDark} alt='LinkedIn logo'/>
              </div>
              </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

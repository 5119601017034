import React from "react";
import '../GeneralProjects.css';

import ProjectHeader from '../ProjectHeader/ProjectHeader.js';
import ImagesRail from "../../../SmallComponents/ImagesRail/ImagesRail.js";
import SingleImg from '../../../SmallComponents/SingleImg/SingleImg.js';
import MoreProjects from "../../MoreProjects/MoreProjects.js";
import Quote from '../../../SmallComponents/Quote/Quote.js';

import GoodreadsText from '../../../Texts/Goodreads.json';

import GRmybooksPhone from '../../../Images/Goodreads/myBooksPhone.png';
import original1 from '../../../Images/Goodreads/original-1.jpg';
import original2 from '../../../Images/Goodreads/original-2.jpg';
import original3 from '../../../Images/Goodreads/original-3.jpg';
import userFlow from '../../../Images/Goodreads/user-flow.jpg';
import home from '../../../Images/Goodreads/wireframes/Home.png';
import homeOptions from '../../../Images/Goodreads/wireframes/Home - options.png';
import homeAdd from '../../../Images/Goodreads/wireframes/Home - Add to shelf.png';
import mybooks from '../../../Images/Goodreads/wireframes/My books.png';
import mybooksOptions from '../../../Images/Goodreads/wireframes/My books - options.png';
import mybooksRead from '../../../Images/Goodreads/wireframes/My books - Read.png';
import bookDetails from '../../../Images/Goodreads/wireframes/Book details.png';
import bookDetailsReading from '../../../Images/Goodreads/wireframes/Book details - currently reading.png';
import bookDetailsRead from '../../../Images/Goodreads/wireframes/Book details - read.png';


const GoodreadsComponent = () => {

  const OriginalImg = [original1, original2, original3];
  const lowfidHome = [home, homeOptions, homeAdd];
  const lowfidMyBooks = [mybooks, mybooksOptions, mybooksRead];
  const lowfidBookDetails = [bookDetails, bookDetailsReading, bookDetailsRead];

  return (
    <div className='project-page'>
        <ProjectHeader title={GoodreadsText.title} overview={GoodreadsText.subtitle} image={GRmybooksPhone}/>

        <div className="project-content">

        <div className='project-section'>
            <div className='project-section-text'>
                <h2 className='text-head'>{GoodreadsText.background.title}</h2>
                <p className='text-main'>{GoodreadsText.background.paragraph}</p>
            </div>
          </div>

          <div className='project-section'>
            <div className='project-section-text'>
                <h2 className='text-head'>{GoodreadsText.research.title}</h2>
                <p className='text-main'>{GoodreadsText.research.paragraphs.one}</p>

                <ul className="subsequent-paragraph normal-ul text-main">
                  <li>
                    <span className='bold-text'>{GoodreadsText.research.points.one.title}</span>
                    <ul className="normal-ul text-main sub-ul">
                      <li className="text-main">{GoodreadsText.research.points.one.subPoints.one}</li>
                      <li>{GoodreadsText.research.points.one.subPoints.two}</li>
                      <li>{GoodreadsText.research.points.one.subPoints.three}</li>
                    </ul>
                  </li>
                  <li>
                    <span className='bold-text'>{GoodreadsText.research.points.two.title}</span>
                    <ul className="normal-ul text-main sub-ul">
                      <li>{GoodreadsText.research.points.two.subPoints.one}</li>
                      <li>{GoodreadsText.research.points.two.subPoints.two}</li>
                    </ul>
                  </li>
                </ul>

                <p className='text-main subsequent-paragraph'>{GoodreadsText.research.paragraphs.two}</p>
            </div>
          </div>


        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{GoodreadsText.jtbd.title}</h2>
            <p className='text-main'>{GoodreadsText.jtbd.paragraph}</p>
            {/* <p className='text-main italic-paragraph'>{GoodreadsText.jtbd.jtbd}</p> */}
          </div>
          <div className="slide-up">
              <Quote quote={GoodreadsText.jtbd.jtbd} className='project-quote'/>
            </div>
        </div>

        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{GoodreadsText.users.title}</h2>
            <p className='text-main'>{GoodreadsText.users.paragraphs.one}</p>
            <p className='text-main subsequent-paragraph'>{GoodreadsText.users.paragraphs.two}</p>

            <div className="sub-area ">
              <h3 className='text-subhead'>{GoodreadsText.users.subAreas.area1.title}</h3>
              <p className='text-main'>{GoodreadsText.users.subAreas.area1.paragraph}</p>

              <ul className="subsequent-paragraph normal-ul text-main">
                <li><span className='bold-text'>{GoodreadsText.users.subAreas.area1.points.one.title}</span> {GoodreadsText.users.subAreas.area1.points.one.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.users.subAreas.area1.points.two.title}</span> {GoodreadsText.users.subAreas.area1.points.two.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.users.subAreas.area1.points.three.title}</span> {GoodreadsText.users.subAreas.area1.points.three.paragraph}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{GoodreadsText.testing.title}</h2>
            <p className='text-main'>{GoodreadsText.testing.paragraph}</p>
            <div className='sub-area ' >
              <h3 className='text-subhead'>{GoodreadsText.testing.subAreas.area1.title}</h3>
              <p className='text-main'>{GoodreadsText.testing.subAreas.area1.paragraphs.one}</p>

              <ul className="subsequent-paragraph normal-ul text-main">
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area1.points.one.title}</span> {GoodreadsText.testing.subAreas.area1.points.one.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area1.points.two.title}</span> {GoodreadsText.testing.subAreas.area1.points.two.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area1.points.three.title}</span> {GoodreadsText.testing.subAreas.area1.points.three.paragraph}</li>
              </ul>

              <p className='text-main subsequent-paragraph'>{GoodreadsText.testing.subAreas.area1.paragraphs.two}</p>
            </div>
          </div>
          <ImagesRail images={OriginalImg} />
          <div className='project-section-text'>
            <div className='sub-area'>
              <h3 className='text-subhead'>{GoodreadsText.testing.subAreas.area2.title}</h3>
              <p className='text-main'>{GoodreadsText.testing.subAreas.area2.paragraphs.one}</p>

              <ul className="subsequent-paragraph normal-ul text-main">
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area2.points.one.title}</span> {GoodreadsText.testing.subAreas.area2.points.one.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area2.points.two.title}</span> {GoodreadsText.testing.subAreas.area2.points.two.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area2.points.three.title}</span> {GoodreadsText.testing.subAreas.area2.points.three.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area2.points.four.title}</span> {GoodreadsText.testing.subAreas.area2.points.four.paragraph}</li>
                <li><span className='bold-text'>{GoodreadsText.testing.subAreas.area2.points.five.title}</span> {GoodreadsText.testing.subAreas.area2.points.five.paragraph}</li>
              </ul>

              <p className='text-main subsequent-paragraph'>{GoodreadsText.testing.subAreas.area2.paragraphs.two}</p>
            </div>
          </div> 
          

        </div>

        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{GoodreadsText.solution.title}</h2>
            <p className='text-main'>{GoodreadsText.solution.paragraphs.one}</p>
          </div>
          <SingleImg image={userFlow} label='User Flow'  />
          

          <div className='sub-area'>
            <div className='project-section-text'>
              <h3 className='text-subhead'>{GoodreadsText.solution.subAreas.area1.title}</h3>
              
              <ul className="subsequent-paragraph normal-ul text-main">
                <li>{GoodreadsText.solution.subAreas.area1.points.one}</li>
                <li>{GoodreadsText.solution.subAreas.area1.points.two}</li>
              </ul>
            </div>
            <ImagesRail images={lowfidHome} />
          </div>
          <div className='sub-area'>
            <div className='project-section-text'>
              <h3 className='text-subhead'>{GoodreadsText.solution.subAreas.area2.title}</h3>
              
              <ul className="subsequent-paragraph normal-ul text-main">
                <li>{GoodreadsText.solution.subAreas.area2.points.one}</li>
                <li>{GoodreadsText.solution.subAreas.area2.points.two}</li>
                <li>{GoodreadsText.solution.subAreas.area2.points.three}</li>
              </ul>
            </div>
            <ImagesRail images={lowfidMyBooks} />
          </div>
          <div className='sub-area'>
            <div className='project-section-text'>
              <h3 className='text-subhead'>{GoodreadsText.solution.subAreas.area3.title}</h3>
              
              <ul className="subsequent-paragraph normal-ul text-main">
                <li>{GoodreadsText.solution.subAreas.area3.points.one}</li>
                <li>
                  {GoodreadsText.solution.subAreas.area3.points.two.paragraph}
                  <ul className="normal-ul text-main sub-ul">
                    <li>{GoodreadsText.solution.subAreas.area3.points.two.subPoints.one}</li>
                    <li>{GoodreadsText.solution.subAreas.area3.points.two.subPoints.two}</li>
                    <li>{GoodreadsText.solution.subAreas.area3.points.two.subPoints.three}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <ImagesRail images={lowfidBookDetails} />
          </div>

   
        </div>
        <div className='project-section'>
          <div className='project-section-text'>
            <h2 className='text-head'>{GoodreadsText.conclusion.title}</h2>
            <p className='text-main'>{GoodreadsText.conclusion.paragraph}</p>
          </div>
        </div> 

        <MoreProjects project1='habithero' project2='harleyhospital' />

      </div>
    </div>
  );
};

export default GoodreadsComponent;


import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import CMlogoDarkThin from '../../Images/Logo/CMlogo-dark-thin.png';

import './Navbar.css'


const Navbar = (props) => {
    const { dynamic } = props;
    const location = useLocation();

    const [scrolling, setScrolling] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsMenuOpen(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
                window.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', handleResize);
        }
    }, []);
  
    
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const isCollapseNavbar = scrolling || isMenuOpen || !dynamic ? true : false;

    const ListComponent = () => {
        return (
        <ul className="navbar-nav ms-auto justify-content-end">
            <li><a className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} href="/">Portfolio</a></li>
            <li><a className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} href="/about">About</a></li>
            <li className='nav-link split-li'><span/></li>
            <li ><a className='nav-link' href="https://www.linkedin.com/in/crismary/" target='_blank' rel='noopener noreferrer'>LinkedIn</a></li>
            <li><a className='nav-link' href="mailto:crismary.design@gmail.com">Contact Me</a></li>
        </ul>
        );
      };

      const logoSrc = CMlogoDarkThin;


    return (
        <>
        <nav className={`navbar navbar-light navbar-expand-md fixed-top ${isCollapseNavbar ? 'navbar-collapsed' : ''}`} id='mynav'>
            <a className="navbar-brand" href='/'>
                <img src={logoSrc} className="d-inline-block align-center nav-logo" alt="CM - Crismary's logo" />
                Crismary G.
            </a>
            <button
                className="navbar-toggler"
                type="button"
                onClick={toggleMenu}
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="nav collapse navbar-collapse" id="navbarMain">
                <ListComponent />
            </div>
        </nav>

        {isMenuOpen && (
            <div className='navbar-menu'>
                <ListComponent />
            </div>
        )}
        </>
    )
};
  

export default Navbar;

import React, { useState, useEffect } from "react";
import './ScrollDownIndicator.css';


const ScrollDownIndicator = ({className}) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setIsVisible(true);
        }, 200); 
    
        return () => clearTimeout(timer);
      }, []); 
    
    return (
        <div className={`scroll-box ${isVisible ? 'visible ' : ''} ${className}`}>
            <div className='scroll-indicator'></div>
        </div>
  );
};

export default ScrollDownIndicator;
import React from "react";
import './ActionButton.css';


const ActionButton = ( {buttonText, className} ) => {
    return (
        <div className={`button_slide slide_right ${className}`}>
            {buttonText}
        </div>
    );
};

export default ActionButton;